<template>
  <section>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>
          {{ vehicle.model.make.name | uppercase }} {{ vehicle.model.name | uppercase }}
        </h1>

        <span>
          <v-btn
            class="mr-3"
            color="warning"
            :to="{ name: 'vehicle-form', params: { uuid } }"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn
            color="error"
            @click="handleDeleteModal"
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <b>Marca:</b> {{ vehicle.model.make.name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Modelo:</b> {{ vehicle.model.name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Tipo:</b> {{ vehicle.type.name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Motor:</b> {{ vehicle.motor }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <Modal
      :show.sync="showDeleteModal"
      @submit="remove"
    >
      <template #title>Eliminar</template>

      <template #body>
        ¿Deseas eliminar el vehículo
        <b>{{ vehicle.model.make.name | uppercase }} {{ vehicle.model.name | uppercase }}</b>
        ?
      </template>

      <template #action>Eliminar</template>
    </Modal>
  </section>
</template>

<script>
// API
import VehicleAPI from '@/api/vehicle';

// Mixins
import DetailMixin from '@/mixins/DetailMixin';

export default {
  name: 'VehicleDetail',

  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  mixins: [
    DetailMixin,
  ],

  data: () => ({
    vehicle: {
      model: {
        make: {},
      },
      type: {},
    },
    listRouteName: 'vehicle-list',
  }),

  computed: {
    breadcrumbs() {
      const name = this.vehicle.model
        ? `${this.vehicle.model.make.name} ${this.vehicle.model.name}`
        : '';
      return [
        {
          text: 'Vehículos',
          disabled: false,
          exact: true,
          to: { name: 'vehicle-list' },
        },
        {
          text: name.toUpperCase(),
          disabled: true,
        },
      ];
    },
  },

  async created() {
    this.api = new VehicleAPI();
    await this.detail();
  },

  methods: {
    async detail() {
      this.$store.commit('showLoader');
      try {
        this.vehicle = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
